export default {
  // Endpoints
  loginEndpoint: '/api/login_check',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/api/token/refresh',
  logoutEndpoint: '/jwt/logout',
  resetPasswordRequestEndpoint: '/api/reset-password-request',
  resetPasswordEndpoint: '/api/reset-password',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageOrganisationId: 'organisationId',
  storageOrganisationName: 'organisationName',
}
